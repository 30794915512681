<template>
  <div class="view-container">
    <div class="view">
      <div class="view-body">
        <el-button
            @click="openModal()"
            type="primary"
            v-permission="['pm-member-create']"
        >
          {{ $t('system.add_members') }}
        </el-button>
        <el-dialog
            :title="$t('system.add_members')"
            :visible.sync="dialogTableVisible"
            class="member-modal"
        >
          <form-member-modal
              :groupList="groupList"
              :load="loading.member"
              :roleList="roleList"
              :userList="userList"
              v-on:close="close()"
              v-on:save="save()"
          />
        </el-dialog>
      </div>
    </div>
    <div class="view">
      <div class="view-body">
        <collapse-table
            :checked="checkedRoles"
            :data="model"
            :options="options"
            :thead="thead"
            @closeRoleForm="closeRoleForm"
            @isDisabled="isDisabled"
            @removeElement="removeElement"
            @saveRoleForm="saveRoleForm"
            @showAvabileRoles="showAvabileRoles"
        ></collapse-table>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import swal from 'sweetalert2';
import _ from 'lodash';
import FormMemberModal from '../../components/modals/FormMemberModal.vue';
import CollapseTable from '../../components/ABtable/CollapseTable.vue';
import notification from '../../notification/notify';

export default {
  name: 'MemberIndex',
  components: {
    FormMemberModal,
    CollapseTable,
  },
  data() {
    return {
      canBeShown: false,
      title: 'Members',
      source: 'member/',
      create: '/member/create',
      model: [],
      project_groups: [],
      resource: 'project',
      redirect: '/project',
      remove: true,
      userList: {},
      roleList: {},
      groupList: {},
      roleForm: {},
      checkedRoles: [],
      disabledRoles: [],
      dialogTableVisible: false,
      loading: {
        table: false,
        member: false,
      },
      current: '',
      show: true,
      load: true,
      thead: [
        {
          label: 'system.name',
          key: 'name',
          sort: false,
          type: 'collapse',
          deleteWrap: true,
        },
        {
          label: 'system.role',
          key: 'rola',
          sort: false,
          type: 'rola',
        },
        {
          label: '',
          key: 'action',
          sort: false,
          type: 'action',
        },
      ],
      options: {
        currentShow: false,
        roleList: {},
        permissions: {
          edit: ['pm-member-update'],
          delete: ['pm-member-delete'],
        },
        disabledRoles: [],
      },

    };
  },
  watch: {
    checkedRoles(newVal) {
    },
  },
  beforeMount() {
    if (typeof (this.$route.params.projectId) !== 'undefined') {
      this.title = 'Project Members';
      this.source = 'project/' + this.$route.params.projectId + '/member';
      this.create = '/project/' + this.$route.params.projectId + '/member/create';
    }
    this.$store.commit('SET_ACTIVE', 'member');
    this.getRole();
    this.fetchData();
  },
  created() {
    setInterval(() => {
      this.canBeShown = !this.canBeShown;
    }, 5000);
  },
  methods: {
    getUser() {
      axios.get(`project/user/${this.$route.params.projectId}`)
          .then(response => {
            if (response.status !== 200) {
              this.error = response.statusText;
              return;
            }
            this.userList = response.data.items;
          })
          .catch(error => {
            // Request failed.
            this.error = error.response.statusText;
          });
    },
    getGroup() {
      axios.get(`project/group/${this.$route.params.projectId}`)
          .then(response => {
            if (response.status !== 200) {
              this.error = response.statusText;
              return;
            }
            this.groupList = response.data.items;
          })
          .catch(error => {
            // Request failed.
            this.error = error.response.statusText;
          });
    },
    save() {
      this.fetchData();
      this.getUser();
      this.getGroup();
      this.getRole();
    },
    getRole() {
      axios.get(`project/role/${this.$route.params.projectId}`)
          .then(response => {
            if (response.status !== 200) {
              this.error = response.statusText;
              return;
            }
            this.roleList = response.data.items;
            this.options.roleList = response.data.items;
          })
          .catch(error => {
            // Request failed.
            this.error = error.response.statusText;
          });
    },
    close() {
      this.dialogTableVisible = false;
      // this.fetchData();
    },
    openModal() {
      this.loading.member = true;
      this.getUser();
      this.getGroup();
      this.getRole();
      this.loading.member = false;
      this.dialogTableVisible = true;
    },
    showButtonRemove: function (element) {
      let vm = this;
      if (vm.disabledRoles) {
        var disabledUserRole = vm.disabledRoles.filter(function (e) {
          return e.startsWith('u-' + element.id + '-');
        });
        if (element && disabledUserRole) {
          if (element.roles) {
            if (Object.keys(element.roles).length === disabledUserRole.length) {
              return false;
            }
          }
        }
      }
      return true;
    },
    removeElement: function (element) {
      var vm = this;
      let type = 'user';
      if (typeof element.group !== 'undefined') {
        type = 'group';
      }
      swal.fire({
        title: vm.$t('system.remove'),
        text: vm.$t('system.if_you_sure'),
        type: 'error',
        showCancelButton: true,
        focusCancel: true,
        confirmButtonText: vm.$t('system.yes'),
        cancelButtonText: vm.$t('system.no'),
      }).then((result) => {
        if (result.value) {
          axios.post(`member/destroy`, {
            id: element.id,
            type: type,
          })
              .then(function (response) {
                vm.fetchData();
                notification.notify(
                    vm.$t('notify.success'),
                    vm.$t('system.deleted'),
                    'success');
              });
        }
      }).catch();
    },
    showAvabileRoles(data) {
      this.show = false;
      this.options.currentShow = data.id;
      this.roleForm[data.id] = true;
    },
    closeRoleForm(data) {
      this.roleForm[data.id] = false;
      this.show = true;
      this.options.currentShow = '';
      this.fetchData();
    },
    saveRoleForm(data, cheched) {
      var vm = this;
      axios.put(`member/${this.$route.params.projectId}`, {
        roles: cheched,
        containerId: data.id,
      })
          .then(() => {
            this.checkedRoles = [];
            vm.closeRoleForm(data);
            vm.fetchData();
          });
    },
    fetchData() {
      this.loading.table = true;
      var vm = this;
      vm.checkedRoles = [];
      axios.get(`${this.source}`)
          .then(function (response) {
            if (response.data.model) {
              vm.model = Object.values(response.data.model);
              vm.project_groups = response.data.project_groups;
              vm.disabledRoles = response.data.disabledRoles;
              vm.options.disabledRoles = response.data.disabledRoles;
              _.forEach(response.data.model, function (value, key) {
                _.forEach(value.roles, function (v, k) {
                  vm.checkedRoles.push(
                      key + '-' + v.id,
                  );
                });
              });
            }
            vm.loading.table = false;
            vm.show = true;
            vm.options.currentShow = '';
          })
          .catch(function (error) {
            vm.loading.table = false;
            notification.notify(
                vm.$t('notify.error'),
                vm.$t(error.message),
                'error');
          });
    },
    isDisabled(key) {
      let vm = this;
      if (vm.disabledRoles !== null) {
        if (vm.disabledRoles.indexOf(key) > -1) {
          return true;
        }
      }
    },
  },
};
</script>

<style
    lang="scss"
    rel="stylesheet/scss"
>
.el-dialog__wrapper.member-modal {
  .el-dialog {
    &:first-child {
      width: 80%;
      border-radius: 20px;
      overflow: hidden;

      .el-dialog__header {
        padding: 0 25px;
        display: flex;
        align-items: center;
        min-height: 60px;
        background: linear-gradient(to right, #161D26 20%, #2D3540 60%);

        .el-dialog__title {
          color: #fff;
          font-weight: 600;
          font-size: 18px;
          display: block;
        }

        .title-project {
          font-size: 14px;
          color: #bdbdbd;
        }

        .el-dialog__close {
          color: #fff;
          font-size: 24px;
          font-weight: bold;
        }
      }

      .el-dialog__body {
        padding: 0;
      }
    }
  }
}

.el-collapse-member {
  border: none !important;

  .el-collapse-item__header, .el-collapse-item__wrap {
    background-color: transparent;
  }
}
</style>
