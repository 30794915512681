<template>
  <el-form
      class="p-4"
      label-position="top"
      label-width="120px"
      v-loading="loading"
  >
    <el-form-item :label="$t('system.search_user_or_group')">
      <el-row>
        <el-col :span="8">
          <el-input
              :placeholder="$t('system.search_user_or_group')"
              name="search"
              v-model="search"
          ></el-input>
        </el-col>
      </el-row>
    </el-form-item>
    <el-form-item
        :label="$t('system.users')"
        required
    >
      <ul
          class="member-list"
          v-if="filterUserList.length > 0"
      >
        <li
            :key="item.id"
            v-for="item in filterUserList"
        >
          <el-checkbox
              :class="checkinputRequired('users') ? 'validate-error' : ''"
              name="users"
              v-bind:label="item.id"
              v-bind:value="item.id"
              v-model="checkedUser"
          >
            {{ item.first_name }} {{ item.last_name }}
          </el-checkbox>
        </li>
      </ul>
      <span
          class="no-date-title"
          v-else
      >{{ $t('system.no_data') }}</span>
      <small
          class="help-block"
          v-if="backend_errors.users && !checkinputRequired('users')"
      >
        <span v-html="validationTranslate(backend_errors.users[0])"></span>
      </small>
    </el-form-item>
    <el-form-item :label="$t('system.group')">
      <ul
          class="member-list"
          v-if="filterGroupList.length > 0"
      >
        <li
            :key="'group_' + item.id"
            v-for="item in filterGroupList"
        >
          <el-checkbox
              :class="checkinputRequired('groups') ? 'validate-error' : ''"
              name="groups"
              v-bind:label="item.id"
              v-bind:value="item.id"
              v-model="checkedGroup"
          >
            {{ item.name }}
          </el-checkbox>
        </li>
      </ul>
      <span
          class="no-date-title"
          v-else
      >{{ $t('system.no_data') }}</span>
      <small
          class="help-block"
          v-if="backend_errors.groups && !checkinputRequired('groups')"
      >
        <span v-html="validationTranslate(backend_errors.groups[0])"></span>
      </small>
    </el-form-item>
    <el-form-item
        :label="$t('users.role')"
        required
    >
      <ul
          class="member-list"
          v-if="orderedRoleList.length > 0"
      >
        <li
            :key="role.id"
            v-for="role in orderedRoleList"
        >
          <el-checkbox
              :class="checkinputRequired('roles') ? 'validate-error' : ''"
              name="roles"
              v-bind:label="role.id"
              v-bind:value="role.id"
              v-model="checkedRole"
          >
            {{ role.name }}
          </el-checkbox>
        </li>
      </ul>
      <span
          class="no-date-title"
          v-else
      >{{ $t('system.no_data') }}</span>
      <small
          class="help-block"
          v-if="backend_errors.roles && !checkinputRequired('roles')"
      >
        <span v-html="validationTranslate(backend_errors.roles[0])"></span>
      </small>
    </el-form-item>
    <el-form-item class="mb-0">
      <div class="box-button p-0">
        <el-button
            :loading="loading_save"
            type="primary"
            v-on:click="save"
        >{{ $t('system.save') }}
        </el-button>
        <el-button
            @click.prevent.stop="hide(true)"
            ata-dismiss="member-form-modal"
        >
          {{ $t('system.cancel') }}
        </el-button>
      </div>
    </el-form-item>
  </el-form>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import _ from 'lodash';
import Global from '../../helpers/global';
import notification from '../../notification/notify';

export default {
  name: 'FormMemberModal',
  props: ['userList', 'roleList', 'groupList', 'load'],
  data() {
    return {
      search: '',
      inputRequiredEmpty: [],
      checkedUser: [],
      checkedRole: [],
      checkedGroup: [],
      users: {},
      roles: {},
      groups: {},
      form: {},
      backend_errors: {},
      option: {},
      title: 'Create',
      initialize: 'member/create',
      redirect: '/',
      store: 'member',
      method: 'post',
      resource: 'member',
      loading: this.load,
      loading_save: false,
    };
  },
  // watch: {
  //   '$route': 'fetchData'
  // },
  computed: {
    orderedRoleList() {
      return _.orderBy(this.roleList, function (role) {
        return role.name.toLowerCase();
      });
    },
    filterUserList() {
      let vm = this;
      if (Object.keys(this.$props.userList).length > 0) {
        vm.users = _.orderBy(vm.$props.userList, function (user) {
          return user.name.toLowerCase();
        }, 'asc');
        return this.users.filter(item => {
          let n = item.name.toLowerCase();
          return n.includes(this.search.toLowerCase());
        });
      }
      return _.orderBy(this.$props.userList, function (user) {
        return user.name.toLowerCase();
      }, 'asc');
    },
    filterGroupList() {
      let vm = this;
      if (typeof this.$props.groupList !== 'undefined' && Object.keys(this.$props.groupList).length > 0) {
        vm.groups = _.orderBy(vm.$props.groupList, function (user) {
          return user.name.toLowerCase();
        }, 'asc');
        return this.groups.filter(item => {
          let n = item.name.toLowerCase();
          return n.includes(this.search.toLowerCase());
        });
      }
      return _.orderBy(this.$props.groupList, function (user) {
        return user.name.toLowerCase();
      }, 'asc');
    },
  },
  methods: {
    fetchData() {
      let vm = this;
      vm.loading = true;
      axios.get(this.initialize)
          .then(function (response) {
            Vue.set(vm.$data, 'form', response.data.form);
            Vue.set(vm.$data, 'option', response.data.option);
          })
          .catch(function (error) {
            notification.notify(
                vm.$t('notify.error'),
                error.message,
                'error');
          });
      vm.loading = false;
    },
    save() {
      var vm = this;
      vm.loading_save = true;
      vm.checkValidation().then(function () {
        vm.form.users = vm.checkedUser;
        vm.form.groups = vm.checkedGroup;
        vm.form.roles = vm.checkedRole;
        vm.form.id = vm.$route.params.projectId;
        axios[vm.method](vm.store, vm.form)
            .then(function (response) {
              if (response.data.saved) {
                notification.notify(
                    vm.$t('notify.success'),
                    response.data.message,
                    'success');
                vm.clear(true);
                vm.$emit('save');
                vm.loading_save = false;
                vm.backend_errors = {};
              } else {
                notification.notify(
                    vm.$t('notify.error'),
                    response.data.message,
                    'error');
              }
            })
            .catch(function (error) {
              let dataErrors = error.response.data.errors;
              let errors = Global.getErrors(vm, dataErrors, 1);
              vm.$store.commit('SET_STICKY_ARRAY', {
                array: errors,
              });
              Vue.set(vm.$data, 'backend_errors', dataErrors);
              vm.loading_save = false;
            });
      }).catch(function () {
        vm.loading_save = false;
      });
    },
    hide(emit) {
      if (emit) {
        this.users = {};
        this.roles = {};
        this.groups = {};
        this.checkedRole = [];
        this.checkedGroup = [];
        this.checkedUser = [];
        this.$emit('close');
      }
    },
    clear(data) {
      if (data) {
        this.users = {};
        this.roles = {};
        this.groups = {};
        this.checkedRole = [];
        this.checkedGroup = [];
        this.checkedUser = [];
        // this.$emit('close')
      }
    },
  },
};
</script>
<style
    lang="scss"
    rel="stylesheet/scss"
    scoped
>
.member-list {
  column-count: 3;
  -moz-column-count: 3;
  -webkit-column-count: 3;

  li {
    display: inline-block;
    width: 100%;

    label {
      margin: 0;
    }
  }
}
</style>
<style
    lang="scss"
    rel="stylesheet/scss"
>
.form-modal-members {
  .el-form-item {
    .el-form-item__content {
      margin-left: 0 !important;
      line-height: 100%;
      /*margin-bottom: 20px;*/
      display: block;

      .header {
        label {
          font-size: 18px;
          display: block;
          margin-bottom: 20px;
        }
      }

      .body {
        .role-list {
          list-style-type: none;
          margin: 0;
          max-height: 700px;
          overflow-y: auto;
        }

        .no-data {
          text-align: center;
          color: #C0C4CC;
        }
      }
    }

    &:last-child {
      margin: 0;
    }
  }

}
</style>
